.vilkaarContainer {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.tabContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabButton {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin: 0 5px;
  background: #eee;
  border-radius: 5px;
  transition: background 0.3s;
}

.activeTab {
  color: white;
  background-color: var(--Sand);
}

.vilkaarContent {
  text-align: left;
  line-height: 1.6;
}

.section {
  margin-bottom: 20px;
}

.error {
  color: red;
  font-size: 18px;
  text-align: center;
}
