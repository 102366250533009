.registerForm {
    display: flex;
    justify-content: space-between;
    align-items: stretch; /* Ensures equal height columns */
    max-width: 60vw;
    font-family: Arial, sans-serif;
    color: var(--Black);
    gap: 40px;
    transform: translateX(10%);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 1vw;
    border-radius: 25px;
    min-width: 885px;
}
.leftColumn{
    padding-right: 7vw;

}
.rightColumn{
    justify-content: center;
}
/* Left Column Styling */
.leftColumn, .rightColumn {
    display: flex;
    flex-direction: column;
    flex: 1;  /* Ensures both columns take equal width */
}

/* Form Group Styling */
.formGroup {
    display: relative;
    font-size: 1.2rem;
    font-weight: bold;
}

.formGroup p {
    font-size: 1.2rem;
    color: var(--Green);
    display: flex;
    justify-content: left;
    margin: 4px 2px;
}

.formGroup label {
    color: var(--Green);
}

.formGroup input {
    border-radius: 25px;
    font-size: 1rem;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
    width: 16vw;

}

/* Right Column Styling */
.subscriptionGroup {
    display: flex;
    flex-direction: column;
    font-family: 'GOTHIC', sans-serif;
    gap: 20px;
    padding-bottom: 2vh;
}

.subscriptionGroup h4 {
    font-family: 'AMIA', sans-serif;
    font-size: 1.4rem;
    color: var(--Green);
    margin-bottom: 10px;
}

.subscriptionGroup label {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}
.termsBox {
    padding: 20px;
    background-color: var(--Sand);
    border-radius: 25px;
    color: var(--White);
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    gap: 15px;
    max-width: 100%; /* Use percentage instead */
    width: 400px;  /* Adjust as needed */
}


.termsBox label {
    display: flex;
    align-items: center; /* Aligns the checkbox and text */
    gap: 10px;  /* Adds space between checkbox and text */
    color: var(--White);
}

.termsBox input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid var(--White);
    border-radius: 50%; /* Make it circular */
    background-color: var(--White);
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Style when checkbox is checked */
.termsBox input[type="checkbox"]:checked {
    background-color: var(--White);
    border: 2px solid var(--White);
}

/* Add checkmark using ::after */
.termsBox input[type="checkbox"]:checked::after {
    content: '🗸';  /* Unicode checkmark */
    font-size: 22px;
    color: var(--Green);
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Hover effect */
.termsBox input[type="checkbox"]:hover {
    background-color: var(--White);
    border: 2px solid var(--Green);


}

.termsBox p {
    margin: 0;
    padding: 0;
    font-size: 1rem;
}


.submitButton {
    background-color: var(--Green);
    color: var(--White);
    padding: 15px 0;
    margin-top: 40px;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    width: 100%;

}

.submitButton:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.disclaimer {
    font-size: 0.9rem;
    color: var(--Green);
    text-align: center;
    margin-top: 10px;
    width: 100%;
    text-align: left;
}

.passwordStrength {
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size:1.2rem;
    color: var(--Green);
    z-index: 4;
    padding: 1vh 1vw;
    background-color: var(--White);
    border-radius: 25px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-100%);
}

.inputError {
    
    border: 1px solid  #F40009;
    box-shadow: inset 0 0 10px #d9050c;
}

.inputGood {
    box-shadow: inset 0 0 20px greenyellow;
    border: 1px solid  var(--Green);
}
.errorBox {
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin-top: 10px;
}


/* Responsive Design */
@media (max-width: 768px) {
    .registerForm {
        flex-direction: column;
    }
    .termsBox {
        width: 90%; /* Make it take more space on smaller screens */
        padding: 15px;
    }

    .termsBox label {
        flex-direction: column; /* Stack checkboxes and text */
        align-items: flex-start;
    }

    .termsBox input[type="checkbox"] {
        margin-bottom: 5px;
    }
}


/* Dropdown Styling */
.dropdownContainer {
    position: relative;
    width: 100%;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
    max-height: 150px;
    overflow-y: auto;
    border-radius: 5px;
}

.dropdownItem {
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
}

.dropdownItem:hover {
    background: #f0f0f0;
}