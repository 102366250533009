/* KategoriPage.css */
.pageKategoriContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin: auto;
  background-color: var(--White);
  padding-top: 40px;
  padding-bottom: 20px;
}

.logoContainer {
  height: 60px;
  margin-right: 10px;
}

.searchInput {
  width: 80%;
  z-index: 2;
  border: none;
  outline: none;
}

.searchInput:focus {
  outline: none;
  border: 1px solid var(--Green);
}

/* .searchButton {
    background-color: var(--Green);
    color: var(--White);
    width: 60px;
    height: 40px;
} */

.overlay {
  position: absolute;
  width: 100vw;
  height: 95vh;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.searchbarAndRadio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 8px;
  margin-bottom: 40px;
  font-family: CenturyGothic;
}

.box {
  width: 30%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.boxesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 80%;
  margin: auto;
}

.image {
  cursor: pointer;
  width: 33%;
  height: 33%;
}

.categoryTitle {
  margin: 5px 0 0 0;
  color: var(--Green);
}

.materialColumns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 45%;
  margin: auto;
}

.materialColumn {
  width: 33%;
  display: flex;
  font-family: GOTHIC;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
}

.boxTitle {
  font-family: CenturyGothic;
  margin: 5px 0 0 0;
  color: var(--Green);
}

.materialColumn > p {
  /* Remove full border and only add a top border */
  border: none;
  border-top: 4px solid var(--Sand);
  
  /* Optional styling */
  padding: 12px;
  width: 110px; /* Adjust as needed */
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 48px;
  
  /* Center content if desired */
  display: flex;
  align-items: center;
  justify-content: center;
}







 .materialColumn > p:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}

.box > img {
  cursor: pointer;
  width: 33%;
  height: 33%;
}

.box > h3 {
  font-family: CenturyGothic;
  margin: 5px 0 0 0;
  color: var(--Green);
}







/* Producers */
.nonPayingContainer {
  display: grid;
  place-items: center;
}

.producerGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  justify-content: center;
  margin-bottom: 20px;
  padding: 10px;
}

.payingProducerCard {
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 1px solid #ddd;
}

.payingProducerCard:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Non-Paying Producers list hidden behind toggle */
.nonPayingProducerList {
  padding: 10px;
  margin-top: 20px;
  margin-left: 4vw;
  margin-right: 4vw;
}

.nonPayingProducerListItem {
  padding: 4px;
  background: #f8f8f8;
  border-radius: 6px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  font-size: small;
}

.nonPayingProducerListItem:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.toggleSection {
  margin-top: 20px;
  text-align: center;
  width: fit-content;
  font-size: 18px;
  color: #333;
  cursor: pointer;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f8f8f8;
  transition: background-color 0.2s ease;
}

.toggleSection:hover {
  background-color: #f0f0f0;
}
