/* Main container */
.mainColumn {
  display: flex;
  flex-direction: column;
  background-color: var(--Grey);
  width: 80%;
  max-width: 1220px;
  min-width: 60vw;
  padding: 1%;
  margin: 7px 0;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  font-size: small;
}

.mainColumnProject {
  width: 100%;
  /* max-height: auto; auto is default, so this is omitted */
}

.frontpageimagecontainer {
  min-width: 160px;
  max-width: 25vh;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageStyle {
  max-width: 100%;
  max-height: 227px;
}

.smallImagePlaceholder {
  height: 50px;
  width: 50px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: var(--Grey);
}

.textContainer {
  position: relative;
  flex: 1;
  padding-left: 10px;
}

.imagesBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
}

.titleStyle {
  font-family: GOTHICB, sans-serif;
  color: var(--Green);
  margin-bottom: 1px;
  font-size: 2vh;
}

.categoryStyle {
  font-family: GOTHICB, sans-serif;
  font-size: 1.5vh;
  color: var(--Black);
  margin: 0;
}

.dataStyle {
  font-family: CenturyGothic, sans-serif;
  color: var(--Black);
  font-size: 1.4vh;
  margin: 5px;
}

.unitBox {
  display: flex;
  flex-wrap: wrap;
}

.unitOption {
  display: inline-block;
  padding: 6px 10px;
  font-size: 1.4vh;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid var(--Sand);
  border-radius: 5px;
  color: var(--Black);
  background-color: var(--White);
  transition: background-color 0.3s, border 0.3s;
}

.unitOptionSelected {
  border: 2px solid var(--Sand);
  background-color: var(--Sand);
  color: var(--White);
}

.unitOption:hover {
  background-color: var(--Sand);
  color: var(--White);
  border-color: var(--Sand);
}

/* .detailBoxGroup > * {
  margin-bottom: 20px;
  max-width: 100%;
} */

.certificationImage {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

/* Safety Level */
.safetyLevelContainer {
  margin: 5px;
}
