.helpPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  background-color: var(--White);
}

.toggleContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 700px;
  margin-bottom: 30px;
}

.toggleContainer > * > label {
  white-space: nowrap;
  flex: 1; /* Makes all labels take equal space */
  text-align: center;
  padding: 10px;
  background-color: var(--Sand);
  border-radius: 4px;
  cursor: pointer;
  font-size: medium;
  width: 180px;
}



.mainContent {
  display: flex;
  width: 100%;
  max-width: 1200px;
}



.sideBar {
  width: 250px;
  background-color: var(--Sand);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
}

.sideBarHeader {
  color: var(--White);
  font-weight: bolder;
}

.sideBar > button {
  width: 100%;
  padding: 10px;
  border: none;
  color: var(--White);
  background-color: var(--Sand);
  cursor: pointer;
  font-family: GOTHIC;
  text-align: left;
  font-size: 16px;
  transition: background 0.2s ease-in-out;
  border-radius: 15px;
  margin-bottom: 10px;
}

.sideBar > button:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.sideBar > button.active {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers toggle + content */
  width: 720px; /* Adjust width as needed */
  padding: 20px;
  background-color: var(--White);
  border-radius: 8px;
  /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1); */
}

.inlineReference {
  font-size: 0.75em;        /* smaller than normal text */
  vertical-align: super;    /* raise the text like a superscript */
  color: var(--PrimaryColor); /* use your desired color */
  margin-left: 0.1em;       /* slight spacing from preceding text */
}

.inlineReference a {
  text-decoration: none;    /* remove underline if you prefer */
  color: inherit;           /* inherit the color from the sup */
}

.imageContainer {
  margin: 20px 0;
  text-align: center;
}

.helpImage {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}


.referencesList {
  border-top: 2px solid var(--Black);
  padding-top: 1rem;
  font-family: 'GOTHIC', sans-serif;
  font-size: 10px;
  list-style: none;         /* Remove default list markers */
  counter-reset: refCounter; /* Reset our custom counter */
  margin-left: 0;
  padding-left: 0;
}

.referencesList li {
  position: relative;
  margin-bottom: 0.5rem;
  padding-left: 1.5em; /* space for the marker */
}

.referencesList li::before {
  counter-increment: refCounter; /* Increase counter for each li */
  content: "[" counter(refCounter) "] ";
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0.8em;       /* Slightly smaller, like a superscript */
  vertical-align: super;
  color: inherit;         /* Use the same color as text */
}

/* Container for the tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;

}

/* This is the trigger – you can style it as an icon or text */
.tooltipTrigger {
  font-size: 0.8em;
  color: var(--PrimaryColor);
  margin-left: 0.2em;
}

/* Hidden tooltip content */
.tooltipContent {
  visibility: hidden;
  width: auto;
  position: absolute;
  z-index: 10;
  top: 120%;
  bottom: 125%; /* position above the trigger */
  transform: translateX(-50%);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

/* Show the tooltip on hover */
.tooltip:hover .tooltipContent {
  visibility: visible;
}