/* Container styles */
.container {
    min-width: 700px;
    margin: 50px auto;
    padding: 30px;
    background: var(--White);
    border-radius: 10px;
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

/* Title styles */
.title {
    text-align: center;
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
}

/* Form styling */
.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

/* Input field styling */
.input {
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    font-size: 1rem;
    transition: border 0.3s;
}

.input:focus {
    outline: none;
    border-color: #3498db;
}

/* Button styling */
.button {
  
    padding: 12px;
    width: 80%;
    background-color: var(--Sand);
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
}


/* Error message styling */
.error {
    margin-top: 15px;
    color: #e74c3c;
    text-align: center;
}

/* Success message styling */
.success {
    margin-top: 15px;
    color: #27ae60;
    text-align: center;
}