.pagination {
    display: flex;
    list-style: none;
    gap: 8px;
    justify-content: center;
  }
  
  .pageItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px; /* Adjust as needed */
    height: 40px; /* Adjust as needed */
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid var(--Sand);
    transition: background-color 0.2s;
  }

  
  .pageItem:hover {
    background-color: var(--Sand);
    color: var(--White);
  }
  
  
  
  .pageLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: var(--Black);
  }
  
  .active ,
  .active a {
    background-color: var(--Sand);
    color: var(--White);
  }
  
  .breakItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ddd;
    transition: background-color 0.2s;
    cursor: pointer;
  }
  
  .breakLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: var(--Green);
    font-size: 18px;
    font-weight: bold;
  }
  
  .pageItem:active {
    transform: translateY(0);
    }
  
  /* 
  GO THROUGH THIS ONE BY ONE AND DETERMINE WHAT MESSES IT UP
    
  .pageLink {
    color: var(--Black);
    background-color: var(--White);
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
    font-weight: 500;
  }
  
  .pageLink:hover {
    background-color: var(--Sand);
    color: var(--White);
    transform: translateY(-2px);
  }
  
  .pageLink:active {
    transform: translateY(0);
  }
  
  .active {
    color: var(--White);
    font-weight: 600; /* Make active item bold 
    border: 1px solid var(--Sand); 
  }
  
  .invisible {
    visibility: hidden;
  }
  */
