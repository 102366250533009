/* Whole page */
.pageContainer {
  display: flex;
  grid-template-columns: 1fr 3fr; /* Adjust this as needed */
  grid-gap: 10px;
  padding-top: 30px;
  background-color: var(--White);
  min-height: 100vh;
  margin-top: 3vh;
}

.filterContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 0;
  margin-left: 70px;
  background-color: #f5f5f7;
  width: 280px;
  height: 100%;
  padding: 20px;
  gap: 10px;
}

.titleAndButtons {
  display: flex;
  align-items: center; /* Aligns items vertically */
  justify-content: space-between; /* Ensures spacing between elements */
  width: 100%; /* Ensures it spans the full width */
  gap: 20px; /* Adds spacing between items */
}

.titleAndButtons > div {
  display: flex;
  align-items: center; /* Ensures the content is aligned properly */
}

.pageContainer * h1 {
  font-family: GOTHICB;
  color: var(--Green);
}

.contentContainer {
  margin-left: 4vw;
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Checkbox group */
.checkboxGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.checkboxLabel {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  cursor: pointer;
}

.checkboxLabel input[type="checkbox"] {
  display: none;
}

.checkboxCustom {
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #fff;
  color: black;
}

.checkboxLabel input[type="checkbox"]:checked + .checkboxCustom {
  background-color: #6e573db2;
  color: white;
}

/* Detail box group */
.detailBoxGroup > * {
  margin-bottom: 20px;
  max-width: 100%;
  min-width: 400px;
}

.detailBoxGroup .detailBoxLink > div {
  border-bottom: 1px solid white;
  padding-bottom: 10px;
  min-width: 400px;
}

.detailBoxLink {
  text-decoration: none;
  color: inherit;
  min-width: 400px;
}

.detailBoxLink:hover,
.detailBoxLink:active,
.detailBoxLink:visited {
  text-decoration: none;
  color: inherit;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--Grey);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px; /* Makes the slider round */
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--White);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%; /* Makes the knob circular */
}

input:checked + .slider {
  background-color: var(--Green);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--Green);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Unit box */
.unitBox {
  display: flex;
  margin-left: 5px;
}

.unitOption {
  display: inline-block;
  padding: 6px 10px;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid var(--Sand);
  border-radius: 5px;
  color: var(--Black);
  background-color: var(--White);
  transition: background-color 0.3s, border 0.3s;
}

.unitOption.selected {
  border: 2px solid var(--Sand);
  background-color: var(--Sand);
  color: var(--White);
}

.unitOption:hover {
  background-color: var(--Sand);
  color: var(--White);
  border-color: var(--Sand);
}

/* Styling for the number box container  ... PAGINATION*/
.numberBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* Styling for the page number */
.pageNumber {
  margin: 0;
  padding: 5px;
  font-size: 18px;
  color: var(--Black);
  background-color: var(--White);
  min-width: 40px;
  text-align: center;
}

/* Styling for the navigation buttons - PAGELINK*/
.navButton {
  padding: 8px 8px;
  color: var(--Black);
  background-color: var(--White);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px;
}
/* ARROW */
.arrowIcon {
  font-size: 20px;
}

.navButton:hover {
  background-color: var(--Sand);
  color: var(--White);
}

.navButton:disabled {
  background-color: var(--Sand);
  color: var(--Grey);
  cursor: not-allowed;
}

.dots {
  padding: 5px 5px;
  margin-right: 10px;
}

.queryboxContainer {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next row */
  justify-content: flex-start; /* Aligns items to the start of each row */
  gap: 20px; /* Adds space between the boxes */
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 5px;
  margin-right: 10px;
}

.loaderContainer {
  position: relative;
  width: 100%; /* Ensures the container fills its parent */
  height: 100%; /* Ensures full height */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%; /* Fill the entire parent */
  min-width: 60vw;
  height: 99%; /* Fill the entire parent */
  padding: 17px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  /* background: rgba(255, 0, 0, 0.884); ; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensures it's above content but not too high */
}

/* Styling for the search box */
/* Container for title, unit box, and search bar */
.titleAndSearch {
  display: flex;
  align-items: center; /* Aligns elements vertically */
  justify-content: flex-start; /* Title & Unit Box on the left, Search Box on the right */
  width: 100%;
  gap: 20px;
}

/* Title and Unit Box */
.titleBox {
  display: flex;
  flex-direction: column; /* Title above, Unit Box below */
}

/* Search Box */
.searchBox {
  padding: 8px 12px;
  font-size: 14px;
  width: 15vw; /* Adjust width as needed */
  border: none;
  outline: none;
}

.searchBox:focus {
  outline: none;
}

/* Query Box (Appears Below Title and Unit Box) */
.queryboxContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  margin-top: 15px; /* Ensures spacing below title */
}

.searchContainer {
  position: relative; /* Allows absolute positioning of SearchBarPopup */
  width: fit-content; /* Prevents it from taking full width */
}

/* Responsive Design (for smaller screens) */
@media (max-width: 768px) {
  .pageContainer {
    grid-template-columns: 1fr;
  }

  .filterContainer {
    flex-direction: row; /* Change to horizontal layout */
    flex-wrap: wrap; /* Allow wrapping if needed */
    justify-content: center; /* Center items */
    align-items: center; /* Align items properly */
    width: 100%;
    padding: 10px;
    gap: 10px;
  }

  .titleAndSearch {
    flex-direction: column;
    gap: 15px;
  }

  .searchBox {
    width: 100%;
  }

  .queryboxContainer {
    margin-top: 10px;
  }
}

/* Under 1200px the card becomes unreadable, this stacks it below the filter!*/
@media (max-width: 1200px) {
  .pageContainer {
    flex-direction: column; /* Stack filter and content */
    align-items: center;
    gap: 20px;
  }

  .filterContainer {
    flex-direction: row; /* Change to horizontal layout */
    flex-wrap: wrap; /* Allow wrapping if needed */
    justify-content: center; /* Center items */
    align-items: center; /* Align items properly */
    width: 100%;
    padding: 10px;
    gap: 10px;
  }

  .titleAndSearch {
    flex-direction: column;
    align-items: center; /* Center items */
    text-align: center;
    width: 100%;
  }

  .titleBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .searchBox {
    width: 100%;
    max-width: 400px;
    font-size: 16px;
  }

  .queryboxContainer {
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  .titleAndSearch {
    gap: 4px;
  }

  .pageContainer * h1 {
    font-family: GOTHICB;
    color: var(--Green);
    font-size: large;
  }
}
